import React, { useState, useEffect } from "react"

const GoogleMapsAutocomplete = props => {
  const [inputValue, setInputValue] = useState(props.value ? props.value : "")
  const [predictions, setPredictions] = useState([])
  const [animationState, setAnimationState] = useState(false)
  const [location, setLocation] = useState(null)
  const [error, setError] = useState("")
  const { city } = props
  const [prevCity, setPrevCity] = useState(city)
  useEffect(() => {
    if (city !== prevCity) {
      // Trigger shake animation when city changes and reset input value
      setInputValue("")
      setPrevCity(city) // Update the previous city to the new one
      setAnimationState(true)

      // Reset animation state after the shake animation completes (0.3s duration)
      const timeout = setTimeout(() => {
        setAnimationState(false)
      }, 300)

      // Cleanup timeout
      return () => clearTimeout(timeout)
    }
  }, [city, prevCity]) // Depend on 'city' and 'prevCity' changes
  useEffect(() => {
    if (inputValue.length > 3 && inputValue.length < 8) {
      const autocompleteService = new window.google.maps.places.AutocompleteService()

      autocompleteService.getPlacePredictions(
        { input: inputValue },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            setPredictions(predictions)
          } else {
            setPredictions([])
          }
        }
      )
    } else {
      setPredictions([])
    }
  }, [inputValue])

  const handleInputChange = e => {
    setInputValue(e.target.value)
  }

  const selectLocality = (prediction, title) => {
    console.log("prediction", prediction)
    const geocoder = new window.google.maps.Geocoder()
    setInputValue(title)
    setPredictions([])
    geocoder.geocode({ address: prediction }, (results, status) => {
      if (status === window.google.maps.GeocoderStatus.OK) {
        setLocation(results[0].geometry.location)
        props.onGeoSelected(
          results[0].geometry.location.lat(),
          results[0].geometry.location.lng(),
          prediction
        )
        setError("")
      } else {
        setError(
          "Geocode was not successful for the following reason: " + status
        )
        setLocation(null)
      }
    })
  }
  return (
    <div>
      <input
        type="text"
        className={
          animationState
            ? "form-control homesearchformnew paddingRight inputAnimation shake"
            : "form-control homesearchformnew paddingRight inputAnimation "
        }
        value={inputValue}
        onChange={handleInputChange}
        placeholder="Enter a location"
      />
      <ul className="suggestionList">
        {predictions
          .filter(prediction => prediction.description.includes(props.city))
          .map(prediction => (
            <li
              key={prediction.place_id}
              onClick={() =>
                selectLocality(
                  prediction.description,
                  prediction.structured_formatting.main_text
                )
              }
            >
              {prediction.description}
            </li>
          ))}
      </ul>
    </div>
  )
}

export default GoogleMapsAutocomplete
